import React from 'react';
import cn from 'classnames';

import './index.scss';

import { Container, Row, Column, Text, Link } from 'components/atoms';

import { useFooterMenu } from 'graphql/hooks';

const currentYear = () => {
  let date = new Date();
  const year = date.getFullYear();

  return year;
};

const Footer = ({ footerInverted, pageWidgetsData = [] }) => {
  const { allWordpressWpApiMenusMenusItems } = useFooterMenu();
  const { edges } = allWordpressWpApiMenusMenusItems;

  let isInverted = footerInverted;

  pageWidgetsData = pageWidgetsData.filter(({ __typename }) => {
    return !__typename.includes('footer');
  });

  const widgetsQuantity = pageWidgetsData.length - 1;

  pageWidgetsData &&
    pageWidgetsData.forEach(({ __typename, color_scheme, size }, index) => {
      if (
        __typename === 'WordPressAcf_cta' &&
        index === widgetsQuantity &&
        color_scheme === 'dark' &&
        size === 'large'
      ) {
        isInverted = true;
      }
    });

  const links = edges.map(({ node: { items } }, index) => (
    <div className={cn('menu-main__list_footer-group')} key={index}>
      {items.map(({ title, target, url, rel }, index) => (
        <li
          className={cn('menu-main__list-item menu-main__list_footer-item')}
          key={index}
        >
          <Link
            href={url}
            className={cn('menu-main__list-item__link')}
            target={target || null}
            rel={rel || 'nofollow'}
            isRouter={false}
          >
            <Text
              tag="span"
              color={cn({
                'dark-purple': !isInverted,
                'transparent-green': isInverted,
              })}
              className="subheader"
            >
              {title}
            </Text>
          </Link>
        </li>
      ))}
    </div>
  ));

  const copyrightText = 'Ester Digital is an independent company. Mentioned brands and companies are trademarked brands. © 2015–%d www.ester.co. All rights reserved.'.replace(
    '%d',
    currentYear()
  );

  return (
    <footer className={cn('footer', { footer_dark: isInverted })}>
      <Container type="fluid">
        <Row className="footer__main">
          <Column>
            <nav className="menu-main">
              <ul className="menu-main__list menu-main__list_footer">
                {links}
              </ul>
            </nav>
          </Column>
        </Row>

        <Row
          className={cn('footer__copyright', {
            footer__copyright_dark: isInverted,
          })}
        >
          <Column>
            <Text
              tag="span"
              color={cn({
                'dark-purple': !isInverted,
                'transparent-green': isInverted,
              })}
              className="footer__idiomatic-text"
            >
              {copyrightText}
            </Text>
          </Column>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
